/* width */
::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  position: absolute;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover { }

html {
  width: 100%;
  color: #585755;
  scroll-behavior: smooth;
  font-family: 'B612', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  width: 100%;
  margin: 0;
}

*[role="button"] {
  cursor: pointer;
  color:white;
}


.fa, .far, .fas {
  font-size: 1.7em;
  cursor: pointer;
  color: #585755;
}
